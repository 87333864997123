// Used to prevent googlebot from rendering our page as blank, should be imported first
import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";

import Amplify from 'aws-amplify';


const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));


const isDev = window.location.hostname === 'localhost';

const awsConfig = {
  region: 'us-west-2',
  userPoolId: 'us-west-2_F2yAIWoTU',
  userPoolWebClientId: '2etvbiv0dirpecqqh8efbcbuec',
  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: isDev ? '.localhost' : '.um-less.com',
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: isDev ? false : true
    },
    
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

     // OPTIONAL - Hosted UI configuration
  //    oauth: {
  //     domain: 'login.um-less.com',
  //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  //     redirectSignIn: isDev ? 'http://localhost:3000/' : "https://um-less.com/",
  //     redirectSignOut: isDev ? 'http://localhost:3000/' : "https://um-less.com",
  //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  // }
}

Amplify.configure(awsConfig);

function App() {
  // const md = {'Authorization': 'Bearer '};
  // const client = new UmlessClient('https://api.prod.um-less.com:8443');
  // client.createUpload(new CreateUploadRequest(), md, (err, response) => {
  //   if (err) {
  //     console.error(`Failed to call UmlessAPI ${err.message}`);
  //   } else {
  //     const r = response;
  //     if (r && r !== null && r !== undefined) {
  //       const p = r.getPath();
  //       if (p !== null && p !== undefined) {
  //         console.log(p.getS3path());
  //       }
  //     }

  //   }
  // });
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        {/* Roboto is used by Material-UI, Baloo+Bhaijaan is used for the brand
            name in the navigation */}
        <link
          href="https://fonts.googleapis.com/css?family=Baloo+Bhaijaan|Roboto:300,400,500&display=swap"
          rel="stylesheet"
        />
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
          <Switch>
            <Route path="/c">
              <LoggedInComponent />
            </Route>
            <Route>
              <LoggedOutComponent />
            </Route>
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

serviceWorker.register();

export default App;
